import Router from "next/router";
/**
 * redirectTo
 * @param to
 * @param res
 */ export const redirectTo = function(to) {
    let { res  } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    to = encodeURI(to);
    if (res) {
        res.writeHead(307, {
            "Location": to
        });
        res.end();
    } else {
        Router.replace(to).then(()=>{});
    }
};
export default redirectTo;
